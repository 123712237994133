<template>
  <div class="bottom">
    <div class="bottom_div">
      <!-- <div class="txt_top">
        <div class="left">
          <div class="img"><img src="../../assets/news.png" alt="" /></div>
          <div class="txt">
            今日专科医生就诊328人，预防疫苗108人，健康体检123人...
          </div>
        </div>
        <div class="right">首页 >新闻活动</div>
      </div> -->
      <div class="txt_news"><span>系统</span></div>
      <div class="txt_bottom">
<!--        <News />-->
      </div>
      <div class="system">
        <div class="system_div shadow" @click="openChangePwd">
          <div class="system_div_img shadow system_div_img_on">
            <img src="../../assets/alter-password.png" alt="" />
            <p>修改密码</p>
          </div>
          <div class="system_div_txt">
            <h1>请修改你的账号密码!</h1>
            <p>请修改您的账号密码，同时注意保密修改后的账号密码，谢谢！</p>
          </div>
        </div>
        <div class="system_div shadow" @click="openSetMobile">
          <div class="system_div_img shadow">
            <img src="../../assets/phone.png" alt="" />
            <p>手机号配置</p>
          </div>
          <div class="system_div_txt">
            <h1>请配置您通讯手机号!</h1>
            <p>
              点击进行配置手机号，以方便后期在使用过程中会通过手机短信通知您，谢谢！
            </p>
          </div>
        </div>
        <div class="system_div shadow" @click="openAutoOrder">
          <div class="system_div_img shadow">
            <img src="../../assets/message.png" alt="" />
            <p>是否自动接单</p>
          </div>
          <div class="system_div_txt">
            <h1>请注意!</h1>
            <p>
              如在您没有设置的情况下，系统将自动 为您设置为自动接单；如需调整请在上
              面选择，谢谢！
            </p>
          </div>
        </div>
        <div class="system_div shadow rq">
          <div class="system_div_img shadow">
            <img :src="qr_url" alt="" />
          </div>
          <div class="system_div_txt">
            <h1 class="rq">
              <div class="down">
                <img src="../../assets/download.png" alt="" />
                <p><a :href="qr_url" target="_blank" download="">下载保存</a></p>
              </div>
            </h1>
            <p>请将二维码打印并展示给就诊患者 进行就诊打卡，谢谢！</p>
          </div>
        </div>
      </div>

    </div>
    <el-dialog
      title="修改密码"
      :visible="visible"
      width="500px"
      :destroy-on-close="true"
      :before-close="dialogClose">
      <el-form :model="form" size="medium" label-width="130px" :rules="rules" ref="form" status-icon class="login-form">
        <el-form-item label="旧密码" prop="oldpwd" >
          <el-input v-model="form.oldpwd" type="password"  autocomplete="off" prefix-icon="el-icon-unlock" placeholder="请输入旧密码"></el-input>
        </el-form-item>
        <el-form-item label="新密码" prop="newpwd">
          <el-input v-model="form.newpwd" type="password"  autocomplete="off" prefix-icon="el-icon-unlock" placeholder="请输入新密码"></el-input>
        </el-form-item>
        <el-form-item label="再次输入新密码" prop="newpwd1">
          <el-input v-model="form.newpwd1" type="password"  autocomplete="off" prefix-icon="el-icon-unlock" placeholder="请再次输入新密码"></el-input>
        </el-form-item>
        <el-form-item label-width="0">
          <el-button class="primary-btn" @click="actionData" :loading="$store.state.requestLoading">确定</el-button>
          <el-button @click="resetForm" >重置</el-button>
        </el-form-item>
      </el-form>
    </el-dialog>

    <el-dialog
      title="是否自动接单"
      :visible="autoOrderFormVisible"
      width="400px"
      :destroy-on-close="true"
      :before-close="autoOrderFormClose">
      <el-form :model="autoOrderForm" size="medium" label-width="130px" :rules="autoOrderFormRules" ref="autoOrderForm" status-icon class="login-form">
        <el-form-item label="是否自动接单" prop="is_default_order" style="text-align: left">
          <el-radio-group v-model="autoOrderForm.is_default_order">
            <el-radio label="1">否</el-radio>
            <el-radio label="2">是</el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item label-width="0">
          <el-button class="primary-btn" @click="actionAutoAcceptOrder" :loading="$store.state.requestLoading">确定</el-button>
          <el-button @click="resetAutoOrderForm" >重置</el-button>
        </el-form-item>
      </el-form>
    </el-dialog>

    <el-dialog
      title="手机号配置"
      :visible="mobileVisible"
      width="800px"
      :destroy-on-close="true"
      :close-on-click-modal="false"
      :before-close="cancelSetMobile">
      <el-row :gutter="24">
        <el-col :span="12">
          <el-card class="box-card" style="min-height: 335px;max-height: 335px;overflow-y: scroll">
            <div slot="header" class="clearfix" style="text-align: left">
              <span>已配置的手机号列表</span>
            </div>
            <div v-for="(m, index) in mobileList" :key="index" class="text item" style="text-align: left">
              <div class="mobile-list">
                <div><span>联系人: </span><span>{{m.name}}</span> <span style="float: right"><el-button type="text" size="mini" @click="editMobile(m)">编辑</el-button><el-divider direction="vertical"></el-divider><el-button type="text" size="mini" @click="delMobile(m)">删除</el-button></span></div>
                <div><span>手机号: </span><span>{{m.mobile}}</span></div>
                <div><span>所属区域: </span><span>{{m.type == 1 ? '大陆' : '香港'}}</span></div>
              </div>
            </div>
          </el-card>
        </el-col>
        <el-col :span="12">
          <el-card class="box-card">
            <div slot="header" class="clearfix" style="text-align: left">
              <span>添加手机号</span>
              <el-button style="float: right; padding: 3px 0" type="text" icon="el-icon-plus" @click="addMobile">添加手机号</el-button>
            </div>
            <el-form ref="mobile" :model="mobile" :rules="mobileRules" label-width="80px" size="medium">
              <el-form-item label="联系人" prop="name">
                <el-input v-model="mobile.name" placeholder="请输入联系人"></el-input>
              </el-form-item>
              <el-form-item label="手机号" prop="mobile">
                <el-input v-model="mobile.mobile" placeholder="请输入手机号"></el-input>
              </el-form-item>
              <el-form-item label="所属区域" prop="type" placeholder="请选择所属区域" style="text-align:left">
                <el-radio-group v-model="mobile.type">
                  <el-radio :label="'1'">大陆</el-radio>
                  <el-radio :label="'2'">香港</el-radio>
                </el-radio-group>
              </el-form-item>
              <el-form-item label-width="0">
                <el-button class="primary-btn" @click="actionMobile">{{ mobile.id ? '更新' : '添加' }}</el-button>
                <el-button @click="resetMobile">重置</el-button>
              </el-form-item>
            </el-form>
          </el-card>
        </el-col>
      </el-row>
    </el-dialog>
  </div>
</template>
<style>
  .confirm-cu {
    z-index: 9999;
  }
</style>
<script>
import * as Api from "./api";

export default {
  name: "index",
  components: {},
  data() {
    const checkPwd = (rule, value, callback) => {
      if (value !== this.form.newpwd) {
        callback(new Error('两次输入密码不一致'));
      } else {
        callback();
      }
    };
    return {
      visible: false,
      qr_url: process.env.VUE_APP_API + '/clinic/info/getQRcode?login_code=' + window.localStorage.getItem('login_code'),
      form: {
        oldpwd: '',
        newpwd: '',
        newpwd1: '',
      },
      rules: {
        oldpwd: [
          { required: true, message: '请输入旧密码', trigger: 'change' },
        ],
        newpwd: [
          { required: true, message: '请输入新密码', trigger: 'change' }
        ],
        newpwd1: [
          { required: true, message: '请再次输入新密码', trigger: 'change' },
          { validator: checkPwd, trigger: 'change'}
        ],
      },
      autoOrderForm: {
        is_default_order: ''
      },
      autoOrderFormVisible: false,
      autoOrderFormRules: {
        is_default_order: [
          { required: true, message: '请选择是否自动接单', trigger: 'change' },
        ]
      },
      mobileList: [],
      mobileVisible: false,
      mobile: {
        mobile: '',
        type: '',
        name: ''
      },
      mobileRules: {
        name: [
          { required: true, message: '请输入联系人', trigger: 'change' },
        ],
        mobile: [
          { required: true, message: '请输入手机号', trigger: 'change' }
        ],
        type: [
          { required: true, message: '请选择所属区域', trigger: 'change' },
        ],
      },
    }
  },
  mounted() {
    this.getMobileList();
  },
  methods: {
    /**
     * @author 打开手机号配置
     * */
    openSetMobile() {
      this.mobileVisible = true;
    },
    /**
     * @author 关闭手机号配置
     * */
    cancelSetMobile() {
      this.mobileVisible = false;
      this.resetMobile();
    },
    /**
     * 添加手机号
     * @author wheat
     * */
    addMobile() {
      this.resetMobile();
    },
    /**
     * @author 删除手机号
     * */
    async delMobile(record) {

      let res = await Api.MobileDelete({id: record.id});
      if (res && res.code == 0) {
        this.$message.success(res.message);
        this.resetMobile();
        this.getMobileList();
      } else {
        this.$message.error(res.message);

      }
    },
    /**
     * @author 编辑手机号
     * */
    editMobile(record) {
      this.mobile = record;
    },
    /**
     * @author 添加/编辑手机号
     * */
    actionMobile() {
      this.$refs['mobile'].validate(async (valid) => {
        if (valid) {
          let res = null;
          if (this.mobile.id) {
            res = await Api.MobileUpdate(this.mobile);
          } else {
            res = await Api.MobileSave(this.mobile);
          }
          if (res.code == '0') {

            this.resetMobile();
            this.getMobileList();
            this.$message.success(res.message);
          } else {
            this.$message.error(res.message);
          }
        }
      });
    },
    /**
     * @author 重置手机号字段
     * */
    resetMobile() {
      this.$refs['mobile'].resetFields()
      for(let key in this.mobile) {
        this.mobile[key] = '';
      }
    },
    /**
     * 获取已配置的手机号列表
     * @author wheat
     * */
    async getMobileList() {
      let res = await Api.GetMobileList({});
      this.mobileList = res.list;
    },
    /**
     * 打开修改密码
     * @author wheat
     * */
    openChangePwd() {
      this.visible = true;
    },
    /**
     * 关闭打开修改密码
     * @author wheat
     * */
    dialogClose() {
      this.visible = false;
    },
    /**
     * 修改密码操作
     * @author wheat
     * */
    actionData() {
      this.$refs['form'].validate(async (valid) => {
        if (valid) {
          let res = await Api.ChangePwd(this.form);
          if (res.code == '0') {
            this.visible = false;
            this.$message.success(res.message);
            window.localStorage.removeItem('login_code');
            setTimeout(()=>{
              this.$router.push({ path: '/login' });
            }, 1500);
          } else {
            this.$message.error(res.message);
          }
        }
      });
    },
    /**
     * 打开是否自动接单操作
     * @author wheat
     * */
    async openAutoOrder() {
      this.resetAutoOrderForm();
      let res = await Api.GetGlobalSettings({});
      if (res.code == '0') {
        this.autoOrderForm.is_default_order = res['is_default_order'];
        this.autoOrderFormVisible = true;
      } else {
        this.$message.error(res.message);
      }
    },
    /**
     * 是否自动接单的操作
     * @author wheat
     * */
    async actionAutoAcceptOrder() {
      this.$refs['autoOrderForm'].validate(async (valid) => {
        if (valid) {
          let res = await Api.SetGlobalSettings(this.autoOrderForm);
          if (res.code == '0') {
            this.autoOrderFormVisible = false;
            this.$message.success(res.message);
          } else {
            this.$message.error(res.message);
          }
        }
      });
    },
    /**
     * 关闭是否自动接单
     * @author wheat
     * */
    autoOrderFormClose() {
      this.autoOrderFormVisible = false;
    },
    /**
     * 重置自动接单
     * @author wheat
     * */
    resetAutoOrderForm() {
      this.autoOrderForm.is_default_order = '';
    },
    /**
     * 重新修改密码字段
     * @author wheat
     * */
    resetForm() {
      for(let key in this.form) {
        this.form[key] = '';
      }
    }
  }
};
</script>
<style lang="less">
.system {
  height: 100%;
  background-color: white;
  display: flex;
  justify-content: space-between;
  padding: 0 165px;
  .system_div {
    cursor: pointer;
    width: 302px;
    height: 457px;
    margin: auto 0;
    background-color: white;
    padding: 0 28px;
    text-align: left;
    .system_div_img {
      margin: 0 auto;
      margin-top: 72px;
      width: 193px;
      height: 177px;
      display: flex;
      flex-direction: column;
      border-radius: 10px;
      padding: 22px;
      img {
        margin: auto;
      }
      p {
        text-align: center;
        font-size: 18px;
        color: #999999;
      }
    }
    .system_div_img_on {
      background-color: #1890ff;
      p {
        color: white;
      }
    }
    .system_div_txt {
      margin-top: 45px;
      h1 {
        font-size: 16px;
        color: #ecab78;
        letter-spacing: 2px;
      }
      p {
        margin-top: 16px;
        line-height: 24px;
        font-size: 14px;
        color: #a2a2a2;
        letter-spacing: 1px;
      }
    }
  }
}
.shadow {
  box-shadow: 0px 10px 10px 0px rgba(53, 53, 53, 0.05);
  border-radius: 10px;
}
.rq {
  .system_div_img {
    img {
      height: 133px;
    }
  }
  .system_div_txt {
    h1.rq {
      background-color: #ffb817;
      border-radius: 50px;
      display: flex;
      .down {
        display: flex;
        margin: 0 auto;
        img {
          height: 17px;
          width: 21px;
          margin: auto 0;
          margin-right: 16px;
        }
        p {
          display: block;
          color: white;
          text-align: center;
          line-height: 52px;
          margin: 0;
        }
      }
    }
  }
}.system {
   height: 100%;
   background-color: white;
   display: flex;
   justify-content: space-between;
   padding: 0 165px;
   .system_div {
     width: 302px;
     height: 457px;
     margin: auto 0;
     background-color: white;
     padding: 0 28px;
     text-align: left;
     .system_div_img {
       margin: 0 auto;
       margin-top: 72px;
       width: 193px;
       height: 177px;
       display: flex;
       flex-direction: column;
       border-radius: 10px;
       padding: 22px;
       img {
         margin: auto;
       }
       p {
         text-align: center;
         font-size: 18px;
         color: #999999;
       }
     }
     .system_div_img_on {
       background-color: #1890ff;
       p {
         color: white;
       }
     }
     .system_div_txt {
       margin-top: 45px;
       h1 {
         font-size: 16px;
         color: #ecab78;
         letter-spacing: 2px;
       }
       p {
         margin-top: 16px;
         line-height: 24px;
         font-size: 14px;
         color: #a2a2a2;
         letter-spacing: 1px;
       }
     }
   }
 }
.shadow {
  box-shadow: 0px 10px 10px 0px rgba(53, 53, 53, 0.05);
  border-radius: 10px;
}
.rq {
  .system_div_img {
    img {
      height: 133px;
    }
  }
  .system_div_txt {
    h1.rq {
      background-color: #ffb817;
      border-radius: 50px;
      display: flex;
      .down {
        display: flex;
        margin: 0 auto;
        img {
          height: 17px;
          width: 21px;
          margin: auto 0;
          margin-right: 16px;
        }
        p {
          display: block;
          color: white;
          text-align: center;
          line-height: 52px;
          margin: 0;
        }
      }
    }
  }
}
a{
  text-decoration: underline;
}
.text {
  font-size: 14px;
}

.item {
  margin-bottom: 18px;
}

.clearfix:before,
.clearfix:after {
  display: table;
  content: "";
}
.clearfix:after {
  clear: both
}

.box-card {
}
.mobile-list {
  padding-bottom: 10px;
  border-bottom: 1px solid #eee;
  color: #666666;
  font-size: 14px;
}
</style>

